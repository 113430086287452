<template>
  <app-list-view
    server-side
    app="liability"
    model="liabilitycategory"
    api-url="liability/liability-category/"
    :title="$t('menu.liabilityCategory')"
    :createTo="{ name: 'liabilityCategoryCreate' }"
    :editTo="{ name: 'liabilityCategoryEdit' }"
    :headers="headers"
  />
</template>

<script>
import AppListView from '@components/AppListView'

export default {
  name: 'liabilityCategoryList',
  components: {
    AppListView
  },
  computed: {
    headers() {
      return [
        { text: this.$t('fields.liabilityCategoryName'), value: 'name' },
        { text: this.$t('fields.state'), value: 'state' },
        {
          text: this.$t('fields.liabilityType'),
          value: 'businessCategory',
          link: {
            prepareRouter: this.liabilityTypeLink
          },
          hideFilter: true
        },
        {
          text: this.$t('fields.active'),
          value: 'active',
          check: true,
          groupable: false,
          hideFilter: true
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          groupable: false,
          hideFilter: true
        }
      ]
    }
  },
  methods: {
    liabilityTypeLink(item) {
      return {
        name: 'liabilityType',
        query: { liability_categories: item.id }
      }
    }
  }
}
</script>
